export function seacrhFilter(data, text) {
  const lowerCaseQuery = text.toLowerCase();

  return data.filter(
    (item) =>
      item.type.toLowerCase().includes(lowerCaseQuery) ||
      item.ip.toLowerCase().includes(lowerCaseQuery) ||
      item.name.toLowerCase().includes(lowerCaseQuery)
  );
}
