import React, { useState } from 'react';
import styled from 'styled-components';
import plusIcon from '../../assets/icons/plus.svg';
import searchIcon from '../../assets/icons/search.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import restartIcon from '../../assets/icons/restart.svg';
import downloadIcon from '../../assets/icons/download.svg';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authState, searchText } from '../../states/atoms';
import useModal from '../../hooks/useModal';
import Modal from '../../components/CreateModal';
import ListModal from '../../components/CreateListModal';
import Popup from '../Popup';
import axios from 'axios';
import { config } from '../../config';
import JSZip from 'jszip';

const Index = ({ dataCount, items }) => {
  return (
    <Wrapper>
      <AddButton />
      <AddListButton/>
      <Search />
      <Count>
        Найдено записей:
        <span>{' ' + dataCount}</span>
      </Count>
      <RestartButton />
      <DownloadButton items={items} />
      <ExitButton />
    </Wrapper>
  );
};

export default Index;

const AddButton = () => {
  const [isShowingModal, toggleModal] = useModal();
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({
    msg: '',
    status: true,
  });

  const showMessage = (message, status) => {
    setPopupData({
      msg: message,
      status: status,
    });
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupData({
      msg: '',
      status: true,
    });
  };

  return (
    <>
      <ButtonWrapper onClick={toggleModal}>
        <img src={plusIcon} alt="plus" />
        Добавить конфигурацию
      </ButtonWrapper>
      <Modal
        showMessage={showMessage}
        show={isShowingModal}
        onCloseButtonClick={toggleModal}
      />
      {showPopup && <Popup data={popupData} onClose={closePopup} />}
    </>
  );
};

const AddListButton = () => {
  const [isShowingModal, toggleModal] = useModal();
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({
    msg: '',
    status: true,
  });

  const showMessage = (message, status) => {
    setPopupData({
      msg: message,
      status: status,
    });
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupData({
      msg: '',
      status: true,
    });
  };

  return (
    <>
      <ButtonWrapper onClick={toggleModal}>
        <img src={plusIcon} alt="plus" />
        Добавить конфигурации из диапазона
      </ButtonWrapper>
      <ListModal
        showMessage={showMessage}
        show={isShowingModal}
        onCloseButtonClick={toggleModal}
      />
      {showPopup && <Popup data={popupData} onClose={closePopup} />}
    </>
  );
};

const Search = () => {
  const [text, setText] = useRecoilState(searchText);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <SearchWrapper>
      <img src={searchIcon} alt="search" />
      <SearchInput
        placeholder={'Название, тип, IP'}
        value={text}
        onChange={(e) => handleChange(e)}
      />
    </SearchWrapper>
  );
};

const ExitButton = () => {
  const setAuth = useSetRecoilState(authState);

  const handleExit = () => {
    setAuth(false);
    localStorage.removeItem('ovpn-auth-token');
  };
  return (
    <ButtonWrapper className={'exit'} onClick={handleExit}>
      <img src={logoutIcon} alt="exit" />
      Выход
    </ButtonWrapper>
  );
};

const RestartButton = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({
    msg: '',
    status: true,
  });

  const showMessage = (message, status) => {
    setPopupData({
      msg: message,
      status: status,
    });
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
    setPopupData({
      msg: '',
      status: true,
    });
  };
  const handleRestart = async () => {
    try {
      await axios.post(
        `${config.server_uri}/api/server-restart`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ovpn-auth-token')}`,
          },
        }
      );
      showMessage('Сервер успешно перезагружен.', true);
    } catch (e) {
      console.log(e);
      showMessage(
        'Произошла ошибка на сервере. Потворите попытку позже.',
        false
      );
    }
  };
  return (
    <>
      <ButtonWrapper className={'restart'} onClick={handleRestart}>
        <img src={restartIcon} alt="exit" />
        Перезагрузить сервер
      </ButtonWrapper>
      {showPopup && <Popup data={popupData} onClose={closePopup} />}
    </>
  );
};

const DownloadButton = ({ items }) => {
  const handleDownloads = (items) => {
    const zip = new JSZip();
    items.forEach((item) => {
      // Создаем папку для каждого элемента
      const folder = zip.folder(item.name);
      
      // Если элемент является зарядной станцией, добавляем все необходимые файлы
      if (item.type === 'зарядная станция') {
        folder.file(`${item.name}.key`, item.clientKey);
        folder.file(`${item.name}.crt`, item.clientCrt);
        folder.file('ca.crt', item.caCrt);
        folder.file(`${item.name}.ovpn`, item.ovpn);
        folder.file('readme.txt', `Адрес сервера: vpn.ev-t.ru\nПорт: 1194\nЛокальная подсеть: ${item.network}`);
      } else {
        // Для всех других типов добавляем только файл ovpn
        folder.file(`${item.name}.ovpn`, item.ovpn);
      }
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, 'configs.zip');
    });
  };

  return (
    <ButtonWrapper className={'restart'} onClick={() => handleDownloads(items)}>
      <img src={downloadIcon} alt="exit" />
    </ButtonWrapper>
  );
};
const Wrapper = styled.div`
  padding: 20px 24px;
  display: flex;
  align-items: center;
  gap: 24px;
  position: sticky;
  top: 0;
  z-index: 999;
  background: white;
`;
const Count = styled.p`
  color: #364254;
  font-weight: 300;
  span {
    color: #0070ff;
  }
`;

const ButtonWrapper = styled.div`
  padding: 10px 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  background: #0070ff;
  font-weight: 300;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  line-height: 20px;
  transition: all 0.3s ease;
  &.exit {
    font-weight: 300;
    background: #fff;
    color: #364254;
    border: 1px solid #364254;
    margin-left: auto;
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
    img {
      width: 16px;
      height: 16px;
    }
  }
  &:hover {
    background: #03479f;
  }

  img {
    width: 12px;
    height: 12px;
  }
  &.restart {
    img {
      width: 19px;
      height: 19px;
    }
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  position: relative;
  width: 30%;
  img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 12px;
    top: 10px;
  }
`;
const SearchInput = styled.input`
  padding: 10px 16px 10px 40px;
  color: #364254;
  outline: none;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fcfcfd;
  font-size: 16px;
  width: 100%;
`;
