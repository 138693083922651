import React, { useEffect } from 'react';
import GlobalStyle from './utils/globalStyles';
import Routing from './routing';
import { useSetRecoilState } from 'recoil';
import useAutoLogout from './hooks/useAutoLogout';
import { authState } from './states/atoms';
import { config } from './config';

const App = () => {
  const checkTokenValidity = async () => {
    const token = localStorage.getItem('ovpn-auth-token');
    if (!token) {
      return false;
    }
    try {
      const response = await fetch(`${config.server_uri}/api/auth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return response.ok;
    } catch (error) {
      console.error('Error verifying token:', error);
      return false;
    }
  };

  const setAuth = useSetRecoilState(authState);

  useAutoLogout();
  useEffect(() => {
    checkTokenValidity().then((isValid) => {
      setAuth(isValid);
    });
  }, [setAuth]);

  return (
    <>
      <GlobalStyle />
      <Routing />
    </>
  );
};

export default App;
