import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '../../states/atoms';

const PrivateLogin = ({ children }) => {
  const auth = useRecoilValue(authState);
  return !auth ? children : <Navigate to="/" replace />;
};

export default PrivateLogin;
