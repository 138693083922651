import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TableActionsPanel from '../components/TableActionsPanel';
import Table from '../components/Table';
import { useRecoilValue } from 'recoil';
import { searchText } from '../states/atoms';
import { seacrhFilter } from '../utils/filters';
import { useQuery } from 'react-query';
import { getCfg } from '../data/functions';

const Home = () => {
  const { data, isLoading, isError } = useQuery('getConfigs', getCfg);
  const [filteredData, setFilteredData] = useState([]);
  const searchValue = useRecoilValue(searchText);

  useEffect(() => {
    if (isLoading || isError) {
      return;
    }
    if (data && searchValue.trim() !== '') {
      setFilteredData(seacrhFilter(data, searchValue));
    } else {
      setFilteredData(data || []);
    }
  }, [data, searchValue, isLoading, isError]);

  return (
    <Wrapper>
      <TableActionsPanel dataCount={filteredData.length} items={filteredData} />
      <Table data={filteredData} isLoading={isLoading} isError={isError} />
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #fff;
`;
