export const THs = [
  'Название',
  'Тип',
  'IP',
  'Сеть маршрутизации',
  'Дата создания',
  'До окончания',
  'Тип создания',
  '',
];
export const types = ['Администратор', 'Подрядчик', 'Зарядная станция'];
