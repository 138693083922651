import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Index = ({ data, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExiting(true);
      setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, 300);
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <PopUp
      className={`${data.status ? 'success' : 'error'} ${isVisible ? 'isVisible' : ''} ${isExiting ? 'isExiting' : ''}`}
    >
      <p>{data.msg}</p>
    </PopUp>,
    document.body
  );
};

export default Index;

const PopUp = styled.div`
  color: #55575c;
  position: absolute;
  top: 60px;
  z-index: 9999;
  right: 24px;
  padding: 8px 16px;
  border-radius: 9px;
  transition: transform 0.3s ease;
  webkit-box-shadow: 2px 2px 7px -1px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 2px 2px 7px -1px rgba(0, 0, 0, 0.51);
  box-shadow: 2px 2px 7px -1px rgba(0, 0, 0, 0.51);
  &.error {
    background: rgba(250, 136, 136, 0.9);
  }
  &.success {
    background: rgb(110, 255, 58, 0.3);
  }

  &.isVisible {
    transform: translateX(0); /* Показать попап */
  }

  &.isExiting {
    transform: translateX(200%); /* Скрыть попап */
  }
`;
