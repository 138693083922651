import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../pages/Login';
import PrivateRoute from '../components/PrivateRoute';
import PrivateLogin from '../components/PrivateRoute/PrivateLogin';

const Index = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PrivateLogin>
            <Login />
          </PrivateLogin>
        }
      />
    </Routes>
  );
};

export default Index;
