export const convertDate = (isoString) => {
  const date = new Date(isoString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const daysToEnd = (dateString) => {
  console.log(dateString)
  const date = new Date(dateString);
  const now = new Date();
  const diff = date - now;

  return Math.ceil(diff / (1000 * 60 * 60 * 24));
};
export const getDayWord = (str) => {
  let word = 'дней';
  const days = Number(str);

  if (days % 100 < 11 || days % 100 > 14) {
    switch (days % 10) {
      case 1:
        word = 'день';
        break;
      case 2:
      case 3:
      case 4:
        word = 'дня';
        break;
      default:
        break;
    }
  }
  return word;
};
