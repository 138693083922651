import React, { useState } from 'react';
import styled from 'styled-components';
import { useSetRecoilState } from 'recoil';
import { authState } from '../states/atoms';
import { config } from '../config';

const LoginPage = () => {
  const setAuth = useSetRecoilState(authState);

  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [err, setErr] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      login,
      pass,
    };

    try {
      setErr('');
      const res = await fetch(`${config.server_uri}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const resJson = await res.json();
      if (res.status === 200) {
        localStorage.setItem('ovpn-auth-token', resJson.token);
        setAuth(true);
      } else {
        setErr(resJson.message);
        setPass('');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Wrapper>
      <Title>Авторизация</Title>
      <LoginWrapper>
        {err.length !== 0 && <Error>{err}</Error>}
        <form onSubmit={(e) => handleSubmit(e)}>
          <InputWrapper>
            <p>Логин</p>
            <StyledInput
              type={'text'}
              required
              value={login}
              autoComplete="username"
              onChange={(e) => {
                setLogin(e.target.value);
                setErr('');
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <p>Пароль</p>
            <StyledInput
              type={'password'}
              required
              value={pass}
              autoComplete="current-password"
              onChange={(e) => {
                setPass(e.target.value);
                setErr('');
              }}
            />
          </InputWrapper>

          <LogBtn>Войти</LogBtn>
        </form>
      </LoginWrapper>
    </Wrapper>
  );
};

export default LoginPage;
const Error = styled.div`
  color: #fa6161;
  font-size: 18px;
`;
const Title = styled.h1`
  font-weight: 600;
  color: #4949ff;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;
const LoginWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 10px 0px 30px 0 rgba(226, 236, 249, 1);
  -moz-box-shadow: 10px 0px 30px 0 rgba(226, 236, 249, 1);
  box-shadow: 10px 0px 30px 0 rgba(226, 236, 249, 1);
  border-radius: 16px;
  padding: 24px;
  gap: 16px;
  align-items: center;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  p {
    font-weight: 300;
    font-size: 16px;
  }
`;
const StyledInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 300;
  border-radius: 8px;
  background: white;
  border: 1px solid #afacac;
`;
const LogBtn = styled.button`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background: #4949ff;
  text-align: center;
  border-radius: 8px;
  padding: 12px;
  width: 250px;
  margin-top: 16px;
  transition: all 0.4s ease;
  border: none;

  &:hover {
    background: #9090e7;
  }
`;
