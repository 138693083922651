import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { authState } from '../states/atoms';

const UseAutoLogout = () => {
  const setLogin = useSetRecoilState(authState);

  const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem('ovpn-auth-token');
      if (isTokenExpired(token)) {
        setLogin(false);
        localStorage.removeItem('ovpn-auth-token');
      }
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [setLogin]);
};
export default UseAutoLogout;
