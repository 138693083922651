import axios from 'axios';
import { config } from '../config';

export async function getCfg() {
  const { data } = await axios.get(`${config.server_uri}/api/cfg`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('ovpn-auth-token')}`,
    },
  });
  return data;
}
