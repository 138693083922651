import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '../../states/atoms';
import Loader from '../Loader';

const PrivateRoute = ({ children }) => {
  const auth = useRecoilValue(authState);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAuthChecked(true);
    }, 500);
  }, [auth]);

  if (!authChecked) return <Loader />;

  return auth ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
