import { atom } from 'recoil';

export const searchText = atom({
  key: 'searchvalue',
  default: '',
});
export const authState = atom({
  key: 'auth',
  default: false,
});
