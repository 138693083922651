import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import closeSvg from '../../assets/icons/close.svg';
import { useMutation } from 'react-query';
import axios from 'axios';
import { keyframes } from 'styled-components';
import { queryClient } from '../../index';
import { types } from '../../data/const';
import { config } from '../../config';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Index = ({ showMessage, show, onCloseButtonClick }) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [resData, setResData] = useState({});
  const [isDisabled, setDisabled] = useState(true);

  const [showInputs, setShowInputs] = useState(false);
  const [caCrt, setCrt] = useState('');
  const [caKey, setKey] = useState('');

  const [showInputsIp, setShowInputsIp] = useState(false);
  const [ip, setIp] = useState('');
  const [network, setNetwork] = useState('');

  const handleCheckboxChange = () => {
    setCrt('');
    setKey('');
    setShowInputs(!showInputs);
  };
  const handleCheckboxIpChange = () => {
    setShowInputsIp(!showInputsIp);
  };

  const addCfg = useMutation((data) => {
    return axios.post(
      `${config.server_uri}/api/cfg?name=${data.name}&type=${data.type}&createType=web&crt=${data.crt}&key=${data.key}&ip=${data.ip}&network=${data.network}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ovpn-auth-token')}`,
        },
      }
    );
  });

  const handleSubmit = async () => {
    try {
      const res = await addCfg.mutateAsync({
        name: name,
        type: type.toLowerCase(),
        crt: caCrt,
        key: caKey,
        ip: ip,
        network: network,
      });

      setResData({});
      setName('');
      setType('');

      setCrt('');
      setKey('');

      setIp('');
      setNetwork('');

      setShowInputs(false);
      setShowInputsIp(false);
      showMessage(res.data.msg, true);
      await queryClient.invalidateQueries('getConfigs');
      onCloseButtonClick();
    } catch (e) {
      setResData(e.response.data);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const wrapper = document.getElementById('createModal');
      if (wrapper && !wrapper.contains(event.target)) {
        setResData({});
        setName('');
        setType('');
        setShowInputs(false);
        onCloseButtonClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onCloseButtonClick]);

  useEffect(() => {
    const defaultCondition = name.trim().length !== 0 && type !== '';
    const pathCondition =
      caCrt.trim().length !== 0 && caKey.trim().length !== 0;

    if (showInputs && pathCondition && defaultCondition) {
      setDisabled(false);
      return;
    }
    if (!showInputs && defaultCondition) {
      setDisabled(false);
      return;
    }
    setDisabled(true);
  }, [name, type, showInputs, caKey, caCrt]);

  if (!show) return null;

  return ReactDOM.createPortal(
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Body id="createModal">
        <Header>
          <Title>Добавление конфигурации</Title>
          <CloseBtn
            onClick={() => {
              setResData({});
              setName('');
              setType('');
              setShowInputs(false);
              onCloseButtonClick();
            }}
          >
            <img src={closeSvg} alt="close" />
          </CloseBtn>
        </Header>
        <>
          {JSON.stringify(resData) !== '{}' && (
            <>
              {resData.error ? (
                <ErrorPush>{resData.error}</ErrorPush>
              ) : (
                <SuccessPush>{resData.msg}</SuccessPush>
              )}
            </>
          )}
          <Label>Название:</Label>
          <StyledInput
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setResData({});
            }}
            placeholder={'Введите название'}
          />
        </>
        <>
          <Label>Тип:</Label>
          <StyledSelect
            value={type}
            onChange={(e) => setType(e.target.value)}
            className={type === '' && 'shadow'}
          >
            <option value="" disabled>
              Выберите тип
            </option>
            {types.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </StyledSelect>
        </>
        <div
          style={{
            display: 'flex',
            gap: '12px',
            alignItems: 'center',
            marginTop: '9px',
            marginBottom: '16px',
          }}
        >
          <StyledCheck
            type="checkbox"
            checked={showInputs}
            onChange={handleCheckboxChange}
          />
          <Label style={{ margin: '0' }}>Использовать свой СА</Label>
        </div>
        {showInputs && (
          <>
            <Label>Пусть до СА сертификата:</Label>
            <StyledInput
              value={caCrt}
              onChange={(e) => {
                setCrt(e.target.value);
              }}
              placeholder={'/etc/.../.../ca.crt'}
            />
            <Label>Пусть до СА ключа:</Label>
            <StyledInput
              value={caKey}
              onChange={(e) => {
                setKey(e.target.value);
              }}
              placeholder={'/etc/.../.../ca.key'}
            />
          </>
        )}
        <div
            style={{
              display: 'flex',
              gap: '12px',
              alignItems: 'center',
              marginTop: '9px',
              marginBottom: '16px',
            }}
        >
          <StyledCheck
              type="checkbox"
              checked={showInputsIp}
              onChange={handleCheckboxIpChange}
          />
          <Label style={{ margin: '0' }}>Расширенные настройки</Label>
        </div>
        {showInputsIp && (
            <>
              <Label>IP:</Label>
              <StyledInput
                  value={ip}
                  onChange={(e) => {
                    setIp(e.target.value);
                  }}
                  placeholder={'x.x.x.x'}
              />
              <Label>Сеть маршрутизации:</Label>
              <StyledInput
                  value={network}
                  onChange={(e) => {
                    setNetwork(e.target.value);
                  }}
                  placeholder={'x.x.x.x/x'}
              />
            </>
        )}
        <Footer>
          <AddBtn
            onClick={handleSubmit}
            disabled={isDisabled}
            className={(isDisabled || addCfg.isLoading) && 'disabled'}
          >
            Добавить
          </AddBtn>
        </Footer>
      </Body>
    </Wrapper>,
    document.body
  );
};

export default Index;

const StyledCheck = styled.input`
  height: 21px;
  width: 27px;
  border: 1px solid #d0d5dd;
  cursor: pointer;
`;
const AddBtn = styled.button`
  padding: 10px 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  background: #0070ff;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  line-height: 20px;
  transition: all 0.3s ease;
  border: none;
  font-size: 14px;

  &:hover {
    background: #03479f;
  }

  &.disabled {
    cursor: not-allowed;
    background: #445165;
  }
`;
const Wrapper = styled.div`
  animation: ${fadeIn} 0.2s ease-out forwards;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid #d0d5dd;
`;
const Body = styled.div`
  position: relative;
  background: white;
  width: 40%;
  min-width: 500px;
  border-radius: 12px;
  padding: 18px 20px;
`;
const Title = styled.h3`
  font-size: 21px;
  color: #262626;
  user-select: none;
  font-weight: 400;
`;
const CloseBtn = styled.div`
  cursor: pointer;
  display: flex;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  padding: 4px;
  transition: all 0.3s ease;
  &:hover {
    background: #f2f4f7;
  }
`;
const Label = styled.p`
  color: #313944;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 300;
`;
const StyledInput = styled.input`
  padding: 10px 8px;
  color: #364254;
  outline: none;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fcfcfd;
  font-size: 16px;
  width: 100%;
  margin-bottom: 16px;
  font-weight: 300;
`;
const StyledSelect = styled.select`
  padding: 10px 8px;
  color: #364254;
  outline: none;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fcfcfd;
  font-size: 16px;
  width: 100%;
  margin-bottom: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  font-weight: 300;
  &.shadow {
    color: #757575;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  flex-direction: row-reverse;
`;
const ErrorPush = styled.div`
  background: #ffe0e0;
  padding: 8px;
  border-radius: 8px;
  border: 2px solid #ff9494;
  margin-bottom: 12px;
  color: #262626;
`;
const SuccessPush = styled.div`
  background: #c7f4c7;
  padding: 8px;
  border-radius: 8px;
  border: 2px solid #6bcc40;
  margin-bottom: 12px;
  color: #262626;
`;
