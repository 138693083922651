import React, { useState } from 'react';
import styled from 'styled-components';
import {
  capitalizeFirstLetter,
  convertDate,
  daysToEnd,
  getDayWord,
} from '../../utils/textFormat';
import dotsIcon from '../../assets/icons/dots.svg';
import ActionMenu from '../ActionMenu';
import Popup from '../Popup';
import Loader from '../Loader';
import { THs } from '../../data/const';
import EditModal from "../EditModal";

const Index = ({ data, isLoading, isError }) => {
  const [edit, setEdit] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({
    msg: '',
    status: true,
  });

  const showMessage = (message, status) => {
    setPopupData({
      msg: message,
      status: status,
    });
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
    setPopupData({
      msg: '',
      status: true,
    });
  };
  function handleActionClick(e, item) {
    const rect = e.target.getBoundingClientRect();
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    setSelectedItem({
      ...item,
      coord: {
        x: rect.left + scrollX,
        y: rect.top + scrollY,
      },
    });
  }

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Loader />;
  }

  return (
    <>
      <TableData>
        <thead>
          <tr>
            {THs.map((item, index) => (
              <StyledTH key={index}> {item}</StyledTH>
            ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <tr key={index}>
                <StyledTD>{item.name}</StyledTD>
                <StyledTD>{capitalizeFirstLetter(item.type)}</StyledTD>
                <StyledTD>{item.ip}</StyledTD>
                <StyledTD>{item.network}</StyledTD>
                <StyledTD>{convertDate(item.updatedAt)}</StyledTD>
                <StyledTD>
                  <DayToEnd
                    className={Number(daysToEnd(item.endDate)) <= 7 && 'low'}
                  >
                    {Number(daysToEnd(item.endDate)) <= 0 ? (
                      <>Просрочен</>
                    ) : (
                      <>
                        {daysToEnd(item.endDate) +
                          ' ' +
                          getDayWord(daysToEnd(item.endDate))}
                      </>
                    )}
                  </DayToEnd>
                </StyledTD>
                <StyledTD>{item.createType.toUpperCase()}</StyledTD>
                <StyledTD style={{ textAlign: 'center' }}>
                  <ButtonWrapper onClick={(e) => handleActionClick(e, item)}>
                    <img src={dotsIcon} alt="dots" />
                  </ButtonWrapper>
                </StyledTD>
              </tr>
            ))}
        </tbody>
      </TableData>
      <ActionMenu
        showMessage={showMessage}
        key={selectedItem.id}
        selectedItem={selectedItem}
        setEdit={setEdit}
        onClose={() => setSelectedItem({})}
      />
      <EditModal showMessage={showMessage} show={edit} onCloseButtonClick={()=>setEdit({})}/>
      {showPopup && <Popup data={popupData} onClose={closePopup} />}
    </>
  );
};

export default Index;

const TableData = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  thead {
    position: sticky;
    top: 81px;
    z-index: 999;
  }
`;
const StyledTD = styled.td`
  padding: 12px 24px;
  font-weight: 300;
  border-bottom: 1px solid #eaecf0;
  color: #667085;
  word-wrap: break-word;
`;
const DayToEnd = styled.div`
  width: fit-content;
  padding: 4px 8px;
  border-radius: 12px;
  background: rgba(134, 245, 94, 0.45);
  &.low {
    background: rgba(250, 136, 136, 0.45);
  }
`;
const StyledTH = styled.td`
  padding: 12px 24px;
  text-align: left;
  font-weight: 400;
  background: #fcfcfd;
  border-bottom: 1px solid #eaecf0;
  border-top: 1px solid #eaecf0;
  color: #364254;
  line-height: 18px;
  &:nth-last-child(1),
  &:nth-last-child(2) {
    width: 7%;
  }
  &:nth-last-child(6) {
    width: 10%;
  }
`;

const ButtonWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #f2f4f7;
  }
  img {
    width: 16px;
    height: 16px;
  }
`;
