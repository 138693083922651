import React, { useEffect } from 'react';
import styled from 'styled-components';
import { fadeIn } from '../CreateModal';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { queryClient } from '../../index';
import { config } from '../../config';

const Index = ({ showMessage, selectedItem, onClose, setEdit }) => {
  const handleDownload = (item) => {
    const zip = new JSZip();
    if (item.type === 'зарядная станция') {
      zip.file(`${item.name}.key`, item.clientKey);
      zip.file(`${item.name}.crt`, item.clientCrt);
      zip.file('ca.crt', item.caCrt);
      zip.file(`${item.name}.ovpn`, item.ovpn);
      zip.file('readme.txt', `Адрес сервера: vpn.ev-t.ru\nПорт: 1194\nЛокальная подсеть: ${item.network}`);
    } else {
      // Для всех других типов добавляем только файл ovpn
      zip.file(`${item.name}.ovpn`, item.ovpn);
    }
    
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${item.name}-config.zip`);
    });
  };
  const handleDelete = async (item) => {
    try {
      await axios.delete(`${config.server_uri}/api/cfg?id=${item._id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ovpn-auth-token')}`,
        },
      });
      showMessage('Конфигурация успешно удалена.', true);
    } catch (e) {
      console.log(e);
      showMessage(
        'Произошла ошибка на сервере. Потворите попытку позже.',
        false
      );
    }
  };
  const handleUpdate = async (item) => {
    try {
      await axios.patch(
        `${config.server_uri}/api/cfg?id=${item._id}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ovpn-auth-token')}`,
          },
        }
      );
      showMessage('Сертификат успешно продлен.', true);
    } catch (e) {
      console.log(e);
      showMessage(
        'Произошла ошибка на сервере. Потворите попытку позже.',
        false
      );
    }
  };
  const handleEdit = async (item) => {
    setEdit(item);
  }
  const handleClick = async (action) => {
    await action(selectedItem);
    onClose();
    await queryClient.invalidateQueries('getConfigs');
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const wrapper = document.getElementById('actionMenu');
      if (wrapper && !wrapper.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!selectedItem._id) return null;

  return (
    <Wrapper id="actionMenu" top={selectedItem.coord.y}>
      <div onClick={() => handleClick(handleDownload)}>Скачать</div>
      <div onClick={() => handleClick(handleEdit)}>Изменить</div>
      <div onClick={() => handleClick(handleUpdate)}>Продлить</div>
      <div onClick={() => handleClick(handleDelete)}>Удалить</div>
    </Wrapper>
  );
};

export default Index;

const Wrapper = styled.div`
  position: absolute;

  left: 87.5%;
  top: ${(props) => `${props.top}px` || '0'};

  background: #fff;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  color: #3a3f49;
  font-weight: 300;
  border: 1px solid #eaecf0;
  -webkit-box-shadow: 2px 2px 7px -1px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 2px 2px 7px -1px rgba(0, 0, 0, 0.51);
  box-shadow: 2px 2px 7px -1px rgba(0, 0, 0, 0.51);

  div {
    cursor: pointer;
    padding: 8px 24px;
    transition: all 0.3s ease;
  }

  div:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  animation: ${fadeIn} 0.2s ease-out forwards;
`;
